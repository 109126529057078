<template>
  <div
    class="list-item separator has-background-white column is-full py-2 px-5"
    @click="handleClick"
  >
    <div class="columns is-mobile is-vcentered">
      <div class="column is-3">
        <img
          :src="getImagePath(data)"
          :alt="data.name"
          class="mt-1"
          @error="imageError"
        >
      </div>
      <div class="column is-7 is-size-7">
        <h4 class="has-text-weight-bold mb-2">
          {{ data.name }}
        </h4>
      </div>
      <div class="column is-2">
        <b-icon
          v-if="!isExternalTarget"
          icon="angle-right"
        />
        <b-icon
          v-if="isExternalTarget"
          icon="external-link-alt"
        />
      </div>
    </div>
    <div
      v-if="hasLevel"
      class="level-item"
      :class="levelClass"
    />
  </div>
</template>

<script>
import ImageUtils from '@/utils/image'

export default {
  name: "VideoListItem",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      showDescription: false,
      maxDescLen: 90,
      isValid: true,
      LEVELS: {
        'rojo': 'red-bg',
        'naranja': 'orange-bg',
        'azul': 'blue-bg',
        'verde': 'green-bg'
      }
    }
  },
  computed: {
    levelClass() {
      if (!this.data ||
        !this.data.hasOwnProperty('level') ||
        !this.LEVELS.hasOwnProperty(this.data.level)
      ) {
        return ''
      }

      return this.LEVELS[this.data.level]
    },
    hasLevel() {
      return this.levelClass !== ''
    },
    isExternalTarget() {
      return this.data.hasOwnProperty('is_external') ? this.data.is_external : false
    },
    hasMoreDesc() {

      return this.data.description.length > this.maxDescLen
    }
  },
  methods: {
    getImagePath(item) {
      const videoId = item.video
      return ImageUtils.getYoutubeThumb(videoId)
    },
    imageError() {
      this.isValid = false;
    },
    handleClick() {
      this.$emit('click', this.data)
    }
  }
}
</script>

<template>
  <div
    v-if="data"
    class="page has-background-white with-title"
  >
    <div
      class="video-container"
    >
      <youtube
        ref="youtube"
        :video-id="data.video"
        :fit-parent="true"
        :resize="true"
      />
    </div>
    <div class="column is-12 has-background-white">
      <div class="main-title px-5">
        <h4 class="subtitle mb-1">
          {{ data.title }}
        </h4>
        <div class="is-size-7 mb-3 has-text-weight-semibold has-text-primary">
          <b-icon icon="calendar" />{{ data.created_at | prettyDate }}
          <b-icon icon="folder" />{{ data.gallery.title }}
        </div>
      </div>
      <div
        v-if="data.description"
        class="content px-5"
      >
        <div
          class="has-text-grey-dark"
          v-html="data.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Vídeo',
  name: 'VideoDetail',
  data: function () {
    return {
      data: null,
      section_title: 'Vídeo'
    }
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const id = this.$route.params.id
        this.data = await this.$api.video.getItemById(id)

        this.setPageTitle(`Vídeo - ${this.data.title}`)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    }
  }
}
</script>
